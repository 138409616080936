import Vue from 'vue'
import VueI18n from 'vue-i18n'
import Axios from 'axios'
import { API } from '@/config/api.config'

Vue.use(VueI18n)

// function loadLocaleMessages () {
//   const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
//   const messages = {}
//   locales.keys().forEach(key => {
//     const matched = key.match(/([A-Za-z0-9-_]+)\./i)
//     if (matched && matched.length > 1) {
//       const locale = matched[1]
//       messages[locale] = locales(key)
//     }
//   })
//   console.log(messages)
//   return messages
// }

function loadLocaleMessages () {
  const theMessages = {}
  Axios.get(API.API_GET_LANGUAGES)
    .then((response) => {
      if (response.data.status === 'success') {
        const en = {}
        const de = {}
        const fr = {}
        const it = {}
        const es = {}
        const ro = {}
        const hu = {}
        for (let x = 0; x < response.data.values.length; x++) {
          const theKey = response.data.values[x].key
          const theKeyObj = theKey.split('.')
          if (!en[theKeyObj[0]]) {
            en[theKeyObj[0]] = {}
          }
          if (!de[theKeyObj[0]]) {
            de[theKeyObj[0]] = {}
          }
          if (!fr[theKeyObj[0]]) {
            fr[theKeyObj[0]] = {}
          }
          if (!it[theKeyObj[0]]) {
            it[theKeyObj[0]] = {}
          }
          if (!es[theKeyObj[0]]) {
            es[theKeyObj[0]] = {}
          }
          if (!ro[theKeyObj[0]]) {
            ro[theKeyObj[0]] = {}
          }
          if (!hu[theKeyObj[0]]) {
            hu[theKeyObj[0]] = {}
          }
          en[theKeyObj[0]][theKeyObj[1]] = response.data.values[x].value_en
          de[theKeyObj[0]][theKeyObj[1]] = response.data.values[x].value_de
          fr[theKeyObj[0]][theKeyObj[1]] = response.data.values[x].value_fr
          it[theKeyObj[0]][theKeyObj[1]] = response.data.values[x].value_it
          es[theKeyObj[0]][theKeyObj[1]] = response.data.values[x].value_es
          ro[theKeyObj[0]][theKeyObj[1]] = response.data.values[x].value_ro
          hu[theKeyObj[0]][theKeyObj[1]] = response.data.values[x].value_hu
        }
        theMessages.en = en
        theMessages.fr = fr
        theMessages.de = de
        theMessages.es = es
        theMessages.it = it
        theMessages.ro = ro
        theMessages.hu = hu
      }
    })
  return theMessages
}

export default new VueI18n({
  messages: loadLocaleMessages(),
  locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en'
})
