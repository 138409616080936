import Vue from 'vue'
import Vuex from 'vuex'
import Company from './Company/index'
import Customer from './Customer/index'
import Setting from './Setting/index'
import Ecommerce from './Ecommerce/index'
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    Company,
    Customer,
    Setting,
    Ecommerce
  }
})
