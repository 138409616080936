const apiBase = 'https://api.12booky.com/api/'
// if (location.hostname === 'localhost' || location.hostname === '127.0.0.1') {
// const apiBase = 'http://localhost:8000/api/'
// }
const API_BASE = apiBase

export const API = {
  API_CHECK_COMPANY_TOKEN: API_BASE + 'checkToken',
  API_CHECK_EMPLOYEE_TOKEN: API_BASE + 'checkEmployeeToken',
  API_RESEND_EMAIL: API_BASE + 'resendEmail',
  API_ACTIVATE_ACCOUNT: API_BASE + 'activateAccount',
  API_CHECK_ACCOUNT_TOKEN: API_BASE + 'checkAccountToken',
  API_CHECK_ACCOUNT_CUSTOMER_TOKEN: API_BASE + 'checkAccountCustomerToken',
  API_RECOVER_PASSWORD: API_BASE + 'recoverPassword',
  API_RESET_PASSWORD: API_BASE + 'resetPassword',
  API_RESET_CUSTOMER_PASSWORD: API_BASE + 'resetCustomerPassword',
  API_LOGIN_URL: API_BASE + 'login',
  API_SIGNUP_URL: API_BASE + 'signup',
  API_LOGIN_CUSTOMER_URL: API_BASE + 'loginCustomer',
  API_SIGNUP_CUSTOMER_URL: API_BASE + 'signupCustomer',
  API_SERVICES: API_BASE + 'services',
  API_ADD_SERVICES_TYPE: API_BASE + 'addServiceType',
  API_DELETE_SERVICES_TYPE: API_BASE + 'deleteServiceType',
  API_ADD_SERVICE: API_BASE + 'addService',
  API_DELETE_SERVICES: API_BASE + 'deleteService',
  API_EMPLOYEES: API_BASE + 'employees',
  API_EMPLOYEES_BY_SERVICE: API_BASE + 'employeesByService',
  API_EMPLOYEES_BY_SERVICE_NO_AUTH: API_BASE + 'employeesByServiceNoAuth',
  API_ADD_EMPLOYEE: API_BASE + 'addEmployee',
  API_GET_EMPLOYEE: API_BASE + 'getEmployee',
  API_DELETE_EMPLOYEE: API_BASE + 'deleteEmployee',
  API_GET_AVAILABILITY_BY_EMPLOYEE: API_BASE + 'availabilityByEmployee',
  API_ADD_BOOKING: API_BASE + 'addBooking',
  API_ADD_BOOKING_IOS: API_BASE + 'addBookingIOS',
  API_GET_BOOKING_DETAILS: API_BASE + 'getBookingDetails',
  API_DELETE_BOOKING_CUSTOMER: API_BASE + 'deleteBookingIOS',
  API_UPDATE_BOOKING_PAYMENT: API_BASE + 'updateBookingPaymentIOS',
  API_GET_PLAN_DETAILS: API_BASE + 'getPlanDetails',
  API_SET_VOUCHER: API_BASE + 'setVoucher',
  API_REMOVE_VOUCHER: API_BASE + 'removeVoucher',
  API_SET_SUBSCRIPTION: API_BASE + 'setSubscription',
  API_COMPANY_DETAILS: API_BASE + 'companyDetails',
  API_COMPANY_ADD_DETAILS: API_BASE + 'companyAddDetails',
  API_COMPANY_ACCOUNTS: API_BASE + 'companyAccounts',
  API_GET_COMPANY_LANGUAGE: API_BASE + 'companyGetLanguage',
  API_DELETE_ACCOUNT: API_BASE + 'deleteAccount',
  API_CHECK_USER_ID_AND_ACCOUNT: API_BASE + 'checkAccount',
  API_SAVE_ACCOUNT: API_BASE + 'saveAccount',
  API_ADD_ACCOUNT: API_BASE + 'addAccount',
  API_SAVE_ACCOUNT_PASSWORD: API_BASE + 'saveAccountPassword',
  API_ADD_COMPANY_PROGRAM: API_BASE + 'companyAddProgram',
  API_GET_COMPANY_PROGRAM: API_BASE + 'companyGetProgram',
  API_ADD_COMPANY_TIMEOFF: API_BASE + 'companyAddTimeoff',
  API_GET_COMPANY_TIMEOFF: API_BASE + 'companyGetTimeoff',
  API_GET_COMPANY_NOTIFICATIONS: API_BASE + 'companyGetNotifications',
  API_ADD_COMPANY_NOTIFICATIONS: API_BASE + 'companyAddNotifications',
  API_SAVE_COMPANY_PICTURE: API_BASE + 'companySavePicture',
  API_GET_COMPANY_PAYMENT: API_BASE + 'companyPayment',
  API_CHECK_COMPANY_PAYMENT: API_BASE + 'companyCheckPayment',
  API_SET_COMPANY_PAYMENT: API_BASE + 'companySetPayment',
  API_SET_COMPANY_CURRENCY: API_BASE + 'companySetCurrency',
  API_SET_COMPANY_CASH: API_BASE + 'companySetCash',
  API_SET_COMPANY_TEST_PAYMENT: API_BASE + 'companySetTestPayment',
  API_SAVE_WEBSITE_SETTINGS: API_BASE + 'companySaveWebsiteSettings',
  API_GET_COMPANY_EVENTS: API_BASE + 'companyGetEvents',
  API_GET_AVAILABLE_PAYMENTS: API_BASE + 'companyGetPayments',
  API_GET_AVAILABLE_CURRENCY: API_BASE + 'companyGetCurrency',
  API_SEND_MESSAGE: API_BASE + 'sendUserMessage',
  API_UPDATE_EVENT_DATE: API_BASE + 'updateEventDate',
  API_DELETE_EVENT: API_BASE + 'deleteEvent',
  API_GET_ONHOLD_EVENTS: API_BASE + 'getOnHoldEvents',
  API_SAVE_EVENT: API_BASE + 'saveEvent',
  API_CHANGE_EVENT_WITH_ONHOLD: API_BASE + 'changeEventWithOnHold',
  API_SET_ENDING_DATE: API_BASE + 'setEndingDateAndTotalPrice',
  API_GET_COMPANY_CUSTOMERS: API_BASE + 'companyGetCustomers',
  API_GET_COMPANY_LAST_CUSTOMERS: API_BASE + 'companyGetLastCustomers',
  API_GET_COMPANY_CUSTOMERS_SEARCH: API_BASE + 'companyGetCustomersSearch',
  API_IMPORT_CUSTOMERS: API_BASE + 'importCustomers',
  API_ADD_CUSTOMER: API_BASE + 'addCustomer',
  API_GET_CUSTOMER: API_BASE + 'getCustomer',
  API_DELETE_CUSTOMER: API_BASE + 'deleteCustomer',
  API_GET_FORMATED_DATE: API_BASE + 'getFormatedDate',
  API_SET_FAVORITE_CUSTOMERS: API_BASE + 'setFavoriteCustomer',
  API_GET_USER_DETAILS: API_BASE + 'getUserDetails',
  API_SET_REVOLUT_ORDER: API_BASE + 'setRevolutOrder',
  API_SET_STRIPE_ORDER: API_BASE + 'setStripeOrder',
  API_GET_FIRST_AVAILABLE_EMPLOYEE: API_BASE + 'getFirstAvailableEmployee',
  API_GET_DASHBOARD: API_BASE + 'getDashboard',
  API_EMPLOYEES_REPORTS: API_BASE + 'getEmployeesReports',
  API_CUSTOMERS_REPORTS: API_BASE + 'getCustomersReports',
  API_COSTS_REPORTS: API_BASE + 'getCostsReports',
  API_SERVICES_REPORTS: API_BASE + 'getServicesReports',
  API_SERVICES_TYPE: API_BASE + 'getServiceType',
  API_GET_MESSAGES: API_BASE + 'getMessages',
  API_GET_MESSAGES_FROM: API_BASE + 'getMessagesFrom',
  API_SET_MESSAGES_SEEN_STATUS: API_BASE + 'setSeenStatus',
  API_DELETE_MESSAGES: API_BASE + 'deleteMessages',
  API_SET_WEBSITE_URL: API_BASE + 'setWebsiteUrl',
  API_SAVE_WEBSITE: API_BASE + 'saveWebsite',
  API_GET_WEBSITE: API_BASE + 'getWebsite',
  API_SET_ADMIN_COLORS: API_BASE + 'setAdminColors',
  API_SET_CUSTOMER_ADMIN_COLORS: API_BASE + 'setCustomerAdminColors',
  API_SET_ADMIN_LANG: API_BASE + 'setAdminLang',
  API_SET_ADMIN_CUSTOMER_LANG: API_BASE + 'setCustomerAdminLang',
  API_SAVE_CALENDAR_SETTINGS: API_BASE + 'setCalendarSettings',
  API_GET_CALENDAR_SETTINGS: API_BASE + 'getCalendarSettings',
  API_CONNECT_SOCIAL: API_BASE + 'connectSocial',
  API_CONNECT_SOCIAL_CUSTOMER: API_BASE + 'connectSocialCustomer',
  API_GET_COMPANY_PLAN_INFO: API_BASE + 'planInfo',
  API_GET_UPGRADE_PLANS: API_BASE + 'getUpgradePlans',
  API_SEND_UPGRADE_REQUEST: API_BASE + 'upgradeRequest',
  API_HAS_BOOKINGS_TODAY: API_BASE + 'hasBookingsToday',
  API_SEARCH_BOOKINGS: API_BASE + 'searchBookings',
  API_HISTORY_BOOKINGS: API_BASE + 'getBookingHistory',
  API_GET_REMINDERS: API_BASE + 'getReminders',
  API_GET_REMINDER: API_BASE + 'getReminder',
  API_SAVE_REMINDER: API_BASE + 'saveReminder',
  API_DELETE_REMINDER: API_BASE + 'deleteReminder',
  API_FINALIZE_HELPER: API_BASE + 'finalizeHelper',
  API_GET_COMPANY_VOUCHER: API_BASE + 'getCompanyVoucher',
  API_GET_COMPANY_ACTIVE_VOUCHER: API_BASE + 'getCompanyActiveVoucher',
  API_ADD_COMPANY_VOUCHER: API_BASE + 'addCompanyVoucher',
  API_DELETE_COMPANY_VOUCHER: API_BASE + 'deleteCompanyVoucher',
  API_ADD_COMPANY_VOUCHER_APPLY: API_BASE + 'setCompanyVoucher',
  API_GET_COMPANY_COSTS: API_BASE + 'getCompanyCosts',
  API_ADD_COMPANY_COSTS: API_BASE + 'addCompanyCosts',
  API_DELETE_COMPANY_COSTS: API_BASE + 'deleteCompanyCosts',
  API_CUSTOMER_GET_CUSTOMER: API_BASE + 'getCustomerDetails',
  API_CUSTOMER_ADD_CUSTOMER: API_BASE + 'addCustomerDetails',
  API_CUSTOMER_GET_MESSAGES: API_BASE + 'getCustomerMessages',
  API_CUSTOMER_GET_MESSAGES_FROM: API_BASE + 'getCustomerMessagesFrom',
  API_CUSTOMER_SET_MESSAGES_SEEN_STATUS: API_BASE + 'setCustomerMessagesSeenStatus',
  API_CUSTOMER_DELETE_MESSAGES: API_BASE + 'deleteCustomerMessages',
  API_CUSTOMER_SEND_MESSAGE: API_BASE + 'sendCustomerMessages',
  API_CUSTOMER_GET_BOOKINGS: API_BASE + 'getCustomerBookings',
  API_CUSTOMER_DELETE_BOOKING: API_BASE + 'deleteCustomerBookings',
  API_CUSTOMER_SERVICES: API_BASE + 'getCustomerCompanyServices',
  API_CUSTOMER_GET_COMPANIES: API_BASE + 'getCustomerCompanies',
  API_CUSTOMER_EMPLOYEES: API_BASE + 'getCustomerCompanyEmployees',
  API_CUSTOMER_GET_TRANSLATED_PRICE: API_BASE + 'getCustomerTranslatedPrice',
  API_CUSTOMER_GET_COMPANY_INFO: API_BASE + 'getCustomerCompanyInfo',
  API_CUSTOMER_GET_COMPANY_INFO_UNAUTHENTICATED: API_BASE + 'getCustomerCompanyInfoUnauthenticated',
  API_STORE_TOKEN: API_BASE + 'storeToken',
  API_SEND_PUSH_NOTIFICATION: API_BASE + 'sendWebNotifications',
  API_GET_CUSTOMER_LANG_AND_COLORS: API_BASE + 'getCustomerLangAndColors',
  API_GET_COMPANY_FIRST_TOKEN: API_BASE + 'getCompanyFirstToken',
  API_LIST: API_BASE + 'listDirectory',
  API_GET_CUSTOMER_BOOKINGS: API_BASE + 'getTheCustomerBookings',
  API_GET_CUSTOMER_FILES: API_BASE + 'getCustomerFiles',
  API_PREVIEW_FILE: API_BASE + 'previewCustomerFiles',
  API_DELETE_FILE: API_BASE + 'deleteCustomerFiles',
  API_ADD_CUSTOMER_FILES: API_BASE + 'addCustomerFiles',
  API_GET_CUSTOMER_OBSERVATION: API_BASE + 'getCustomerObservation',
  API_UPDATE_CUSTOMER_OBSERVATION: API_BASE + 'updateCustomerObservation',
  API_UPDATE_CUSTOMER: API_BASE + 'updateCustomerDetails',
  API_ADD_CUSTOMER_DETAILS: API_BASE + 'addCustomerTheDetails',
  API_GET_COMPANY_TEMPLATES: API_BASE + 'getCompanyTemplates',
  API_SAVE_COMPANY_TEMPLATES: API_BASE + 'createCompanyTemplates',
  API_DELETE_COMPANY_TEMPLATES: API_BASE + 'deleteCompanyTemplates',
  API_SAVE_PATIENT_RECORD: API_BASE + 'savePatientRecord',
  API_GET_PATIENT_RECORD: API_BASE + 'getPatientRecord',
  API_SAVE_PATIENT_DIAGNOSIS: API_BASE + 'savePatientDiagnosis',
  API_GET_PATIENT_DIAGNOSIS: API_BASE + 'getPatientDiagnosis',
  API_GET_LANGUAGES: API_BASE + 'getLanguages',
  /* DEFAULT HOMEPAGE */
  API_GET_COMPANIES_ANONYMOUS: API_BASE + 'getCompaniesAnonymous',
  /* E-SHOP */
  API_GET_PRODUCTS: API_BASE + 'getProducts',
  API_ADD_PRODUCT: API_BASE + 'addProduct',
  API_GET_PRODUCTS_BY_CATEGORY: API_BASE + 'getProductsByCategory',
  API_GET_CATEGORIES: API_BASE + 'getCategories',
  API_ADD_CATEGORY: API_BASE + 'addCategory',
  API_EDIT_CATEGORY: API_BASE + 'editCategory',
  API_ADD_SUBCATEGORY: API_BASE + 'addSubcategory',
  API_CHANGE_CATEGORY_ORDER: API_BASE + 'changeCategoryOrder',
  API_DELETE_CATEGORY: API_BASE + 'deleteCategory',
  API_SAVE_SETTINGS: API_BASE + 'saveSettings',
  API_GET_SETTINGS: API_BASE + 'getSettings',
  API_GET_SHIPPING: API_BASE + 'getShipping',
  API_ADD_SHIPPING: API_BASE + 'addShipping',
  API_DELETE_SHIPPING: API_BASE + 'deleteShipping',
  API_GET_HOME_SHIPPING: API_BASE + 'getHomeShipping',
  API_ADD_HOME_SHIPPING: API_BASE + 'addHomeShipping',
  API_FILTER_PRODUCTS: API_BASE + 'getFilterProducts',
  API_CUSTOMER_GET_PRODUCT_INFO: API_BASE + 'getProduct',
  API_CUSTOMER_GET_PRODUCT_RELATED: API_BASE + 'getRelatedProducts'
}
