import Vue from 'vue'
import VueRouter from 'vue-router'

import { core } from '../config/pluginInit'
/* SOCIAL LOGIN */
const FacebookAuth = () => import('../layouts/AuthLayouts/FacebookAuthLayout')

/* Layouts */
const VerticleLayout = () => import('../layouts/VerticleLayout')
const Default = () => import('../layouts/BlankLayout')
const AuthLayout = () => import('../layouts/AuthLayouts/AuthLayout')
const CustomerAuthLayout = () => import('../layouts/AuthLayouts/CustomerAuthLayout')
const HorizantalLayout = () => import('../layouts/HorizantalLayout')
/* Dashboards View */
const Dashboard1 = () => import('../views/Dashboards/Dashboard1.vue')
const DashboardBooky = () => import('../views/Dashboards/DashboardBooky.vue')
const Dashboard3 = () => import('../views/Dashboards/Dashboard3')
const Dashboard4 = () => import('../views/Dashboards/Dashboard4')
const Dashboard5 = () => import('../views/Dashboards/Dashboard5')
const Dashboard6 = () => import('../views/Dashboards/Dashboard6')
/* UIElements View */
const UiAlerts = () => import('../views/core/UiAlerts.vue')
const UiButtons = () => import('../views/core/UiButtons.vue')
const UiBadges = () => import('../views/core/UiBadges.vue')
const UiBreadcrumb = () => import('../views/core/UiBreadcrumb.vue')
const UiColors = () => import('../views/core/UiColors.vue')
const UiTypography = () => import('../views/core/UiTypography.vue')
const UiCards = () => import('../views/core/UiCards.vue')
const UiCarousel = () => import('../views/core/UiCarousel.vue')
const UiEmbedVideo = () => import('../views/core/UiEmbedVideo.vue')
const UiGrid = () => import('../views/core/UiGrid.vue')
const UiModal = () => import('../views/core/UiModal.vue')
const UiListGroup = () => import('../views/core/UiListGroup.vue')
const UiImages = () => import('../views/core/UiImages.vue')
const UiMediaObject = () => import('../views/core/UiMediaObject.vue')
const UiTooltips = () => import('../views/core/UiTooltips.vue')
const UiPopovers = () => import('../views/core/UiPopovers.vue')
const UiNotifications = () => import('../views/core/UiNotifications.vue')
const UiTabs = () => import('../views/core/UiTabs.vue')
const UiPagination = () => import('../views/core/UiPagination.vue')
const UiProgressBars = () => import('../views/core/UiProgressBars.vue')
/* Authentic View */
const defaultPage = () => import('../views/AuthPages/Default/defaultPage')
const companyPage = () => import('../views/Homepage/Company')
const SignIn1 = () => import('../views/AuthPages/Default/Login')
const SignUp1 = () => import('../views/AuthPages/Default/SignUp')
const RecoverPassword1 = () => import('../views/AuthPages/Default/RecoverPassword1')
const SignIn2 = () => import('../views/AuthPages/Customer/Login')
const SignUp2 = () => import('../views/AuthPages/Customer/SignUp')
const RecoverPassword2 = () => import('../views/AuthPages/Customer/RecoverPassword1')
const LockScreen1 = () => import('../views/AuthPages/Default/LockScreen1')
const ConfirmMail1 = () => import('../views/AuthPages/Default/ConfirmMail1')
const Callback = () => import('../views/AuthPages/Default/Callback')
/* Extra Pages */
const ErrorPage = () => import('../views/Pages/ErrorPage')
const ComingSoon = () => import('../views/Pages/ComingSoon')
const Maintenance = () => import('../views/Pages/Maintenance')
const TimeLine = () => import('../views/Pages/TimeLines')
const Pricing = () => import('../views/Pages/Pricing')
const Pricing1 = () => import('../views/Pages/Pricing1')
const BlankPage = () => import('../views/Pages/BlankPage')
const FAQ = () => import('../views/Pages/FAQ')
const Invoice = () => import('../views/Pages/Invoice')
/* Apps Views */
const Notifications = () => import('../views/Notifications/Notifications')
const EmailCompose = () => import('../views/Apps/Email/EmailCompose')
const Calendar = () => import('../views/Apps/Calendar/Calendar')
const ActivateAccount = () => import('../views/AuthPages/Default/Forms/ActivateAccount')
const ResetPassword = () => import('../views/AuthPages/Default/Forms/ResetPassword')
const ResetCustomerPassword = () => import('../views/AuthPages/Default/Forms/ResetCustomerPassword')
const CalendarBook = () => import('../views/Apps/Calendar/CalendarBook')
const Website = () => import('../views/Apps/Website/Website')

const ECommerceListing = () => import('../views/Apps/Ecommerce/Listing')
const Checkout = () => import('../views/Apps/Ecommerce/Checkout')
const Wishlist = () => import('../views/Apps/Ecommerce/Wishlist')
const EcommerceDetail = () => import('../views/Apps/Ecommerce/Detail')
const ChatIndex = () => import('../views/Apps/Chat/Index')
const Services = () => import('../views/Apps/Services/Services')
/* Icon Views */
const IconDripicons = () => import('../views/Icons/IconDripicons')
const IconFontawesome5 = () => import('../views/Icons/IconFontawesome5')
const IconLineAwesome = () => import('../views/Icons/IconLineAwesome')
const IconRemixicon = () => import('../views/Icons/IconRemixicon')
const IconUnicons = () => import('../views/Icons/IconUnicons')
/* Tables Views */
const TablesBasic = () => import('../views/Tables/TablesBasic')
const EditableTable = () => import('../views/Tables/EditableTable')
/* Google Map */
const GoogleMaps = () => import('../views/Maps/GoogleMaps')
/* Form View */
const FormLayout = () => import('../views/Forms/FormLayout')
const FormValidates = () => import('../views/Forms/FormValidates')
const FormSwitches = () => import('../views/Forms/FormSwitches')
const FormRadios = () => import('../views/Forms/FormRadios')
const FormCheckboxes = () => import('../views/Forms/FormCheckboxes')
/* User View */
const Profile = () => import('../views/User/Profile')
const ProfileEdit = () => import('../views/User/ProfileEdit')
const AddUser = () => import('../views/User/AddUser')
const UserList = () => import('../views/User/UserList')

/* Employee View */
const ProfileEditEmployee = () => import('../views/Employee/ProfileEdit')
const AddEmployee = () => import('../views/Employee/AddEmployee')
const EmployeeList = () => import('../views/Employee/EmployeeList')

/* Buy plan view */
const BuyPlan = () => import('../views/Payment/Buy')
const CustomerPaymentIos = () => import('../views/Customer/PaymentIos')

/* Apps View */
const SocialApp = () => import('../views/Apps/Social/SocialApp')
const Reminder = () => import('../views/Apps/Reminder/Reminder')
/* Company View */
const Company = () => import('../views/Company/Company')
// const Accounts = () => import('../views/Company/Accounts')
// const AccountsEdit = () => import('../views/Company/AccountEdit')
// const AccountsAdd = () => import('../views/Company/AddAccount')
const Program = () => import('../views/Company/Program')
const Account = () => import('../views/Company/Account')
const Giftcards = () => import('../views/Company/Giftcards')
const Costs = () => import('../views/Company/Costs')
const Notification = () => import('../views/Company/Notification')
const Payment = () => import('../views/Company/Payment')
const Builder = () => import('../views/Website/Builder')
const ServicesReports = () => import('../views/Reports/ServicesReports')
const GraphicsReports = () => import('../views/Reports/GraphicsReports')
const CustomersReports = () => import('../views/Reports/CustomersReports')
const EmployeesReports = () => import('../views/Reports/EmployeesReports')
const CostsReports = () => import('../views/Reports/CostsReports')
/* Plugins Views */
const DatepickerDemo = () => import('../views/Plugins/DatepickerDemo')
const SelectDemo = () => import('../views/Plugins/SelectDemo')
const DragDropDemo = () => import('../views/Plugins/DragDropDemo')
const AppTreeView = () => import('../views/Plugins/AppTreeView')

/* Customer Views */
const CustomerProduct = () => import('../views/Customer/Product')
const CustomerCart = () => import('../views/Customer/Cart')
const CustomerServices = () => import('../views/Customer/Services')
const CustomerCompany = () => import('../views/Customer/Company')
const CustomerAddBooking = () => import('../views/Customer/AddBooking')
const CustomerBookings = () => import('../views/Customer/Bookings')
const CustomerMessages = () => import('../views/Customer/Notifications')
const CustomerProfile = () => import('../views/Customer/ProfileEdit')
const CustomerSignout = () => import('../views/Customer/SignOut')

/* ESHOP */
const ShopProducts = () => import('../views/Shop/Products')
const ShopOrders = () => import('../views/Shop/Orders')
const ShopShipping = () => import('../views/Shop/Shipping')
const ShopSettings = () => import('../views/Shop/Settings')

Vue.use(VueRouter)

const childRoutes = (prop) => [
  {
    path: 'home-1',
    name: prop + '.home-1',
    meta: { auth: true, name: 'Home 1', layout: 'mini-sidebar-right-fix' },
    component: Dashboard1
  },
  {
    path: 'dashboard',
    name: prop + '.dashboard',
    meta: { auth: true, name: 'Dashboard' },
    component: DashboardBooky
  },
  {
    path: 'home-5',
    name: prop + '.home-5',
    meta: { auth: true, name: 'Home 5', layout: 'two-sidebar' },
    component: Dashboard5
  },
  {
    path: 'home-6',
    name: prop + '.home-6',
    meta: { auth: true, name: 'Home 6', layout: 'icon-with-text' },
    component: Dashboard6
  }
]
const horizontalRoute = (prop) => [
  {
    path: 'home-3',
    name: prop + '.home-3',
    meta: { auth: true, name: 'Home 3', layout: 'nav-with-menu' },
    component: Dashboard3
  },
  {
    path: 'home-4',
    name: prop + '.home-4',
    meta: { auth: true, name: 'Home 4', layout: 'nav-bottom-menu' },
    component: Dashboard4
  }
]
const coreChildRoute = (prop) => [
  {
    path: 'ui-colors',
    name: prop + '.color',
    meta: { auth: true, name: 'Colors' },
    component: UiColors
  },
  {
    path: 'ui-typography',
    name: prop + '.typography',
    meta: { auth: true, name: 'Typography' },
    component: UiTypography
  },
  {
    path: 'ui-alerts',
    name: prop + '.alert',
    meta: { auth: true, name: 'Alert' },
    component: UiAlerts
  },
  {
    path: 'ui-buttons',
    name: prop + '.button',
    meta: { auth: true, name: 'Button' },
    component: UiButtons
  },
  {
    path: 'ui-breadcrumb',
    name: prop + '.breadcrumb',
    meta: { auth: true, name: 'Breadcrumb' },
    component: UiBreadcrumb
  },
  {
    path: 'ui-badges',
    name: prop + '.badges',
    meta: { auth: true, name: 'Badges' },
    component: UiBadges
  },
  {
    path: 'ui-cards',
    name: prop + '.cards',
    meta: { auth: true, name: 'Card' },
    component: UiCards
  },
  {
    path: 'ui-carousel',
    name: prop + '.carousel',
    meta: { auth: true, name: 'Carousel' },
    component: UiCarousel
  },
  {
    path: 'ui-grid',
    name: prop + '.grid',
    meta: { auth: true, name: 'Grid' },
    component: UiGrid
  },
  {
    path: 'ui-embed-video',
    name: prop + '.embed-video',
    meta: { auth: true, name: 'Embed Video' },
    component: UiEmbedVideo
  },
  {
    path: 'ui-modal',
    name: prop + '.modal',
    meta: { auth: true, name: 'Model' },
    component: UiModal
  },
  {
    path: 'ui-listgroup',
    name: prop + '.listgroup',
    meta: { auth: true, name: 'List Group' },
    component: UiListGroup
  },
  {
    path: 'ui-images',
    name: prop + '.images',
    meta: { auth: true, name: 'Image' },
    component: UiImages
  },
  {
    path: 'ui-media-object',
    name: prop + '.media-object',
    meta: { auth: true, name: 'Media Object' },
    component: UiMediaObject
  },
  {
    path: 'ui-pagination',
    name: prop + '.pagination',
    meta: { auth: true, name: 'Paginations' },
    component: UiPagination
  },
  {
    path: 'ui-progressbars',
    name: prop + '.progressbars',
    meta: { auth: true, name: 'Progressbar' },
    component: UiProgressBars
  },
  {
    path: 'ui-tooltips',
    name: prop + '.tooltips',
    meta: { auth: true, name: 'Tooltip' },
    component: UiTooltips
  },
  {
    path: 'ui-popovers',
    name: prop + '.popovers',
    meta: { auth: true, name: 'Popover' },
    component: UiPopovers
  },
  {
    path: 'ui-notifications',
    name: prop + '.notifications',
    meta: { auth: true, name: 'Notification' },
    component: UiNotifications
  },
  {
    path: 'ui-tabs',
    name: prop + '.tabs',
    meta: { auth: true, name: 'Tabs' },
    component: UiTabs
  }
]
const formChildRoute = (prop) => [
  {
    path: 'form-layout',
    name: prop + '.layout',
    meta: { auth: true, name: 'Layout' },
    component: FormLayout
  },
  {
    path: 'form-validate',
    name: prop + '.validate',
    meta: { auth: true, name: 'Validate' },
    component: FormValidates
  },
  {
    path: 'form-switches',
    name: prop + '.switch',
    meta: { auth: true, name: 'Switch' },
    component: FormSwitches
  },
  {
    path: 'form-radios',
    name: prop + '.radio',
    meta: { auth: true, name: 'Radio' },
    component: FormRadios
  },
  {
    path: 'form-checkboxes',
    name: prop + '.checkbox',
    meta: { auth: true, name: 'Checkbox' },
    component: FormCheckboxes
  }
]
const tableChildRoute = (prop) => [
  {
    path: 'tables-basic',
    name: prop + '.basic',
    meta: { auth: true, name: 'Basic' },
    component: TablesBasic
  },
  {
    path: 'editable',
    name: prop + '.editable',
    meta: { auth: true, name: 'Editable' },
    component: EditableTable
  }
]
const iconChildRoute = (prop) => [
  {
    path: 'dripicons',
    name: prop + '.dripicons',
    meta: { auth: true, name: 'Dripicons' },
    component: IconDripicons
  },
  {
    path: 'fontawesome-5',
    name: prop + '.fontawesome-5',
    meta: { auth: true, name: 'Font Awsome' },
    component: IconFontawesome5
  },
  {
    path: 'lineawesome',
    name: prop + '.lineawesome',
    meta: { auth: true, name: 'Line Awsome' },
    component: IconLineAwesome
  },
  {
    path: 'remixicon',
    name: prop + '.remixicon',
    meta: { auth: true, name: 'Remixicon' },
    component: IconRemixicon
  },
  {
    path: 'unicons',
    name: prop + '.unicons',
    meta: { auth: true, name: 'Unicon' },
    component: IconUnicons
  }
]
const appChildRoute = (prop) => [
  {
    path: 'dashboard',
    name: prop + '.dashboard',
    meta: { auth: true, name: 'Dashboard', rights: 'access_dashboard' },
    component: DashboardBooky
  },
  {
    path: 'calendar',
    name: prop + '.calendar',
    meta: { auth: true, name: 'Calendar', rights: 'access_calendar' },
    component: Calendar
  },
  {
    path: 'chat',
    name: prop + '.chat',
    meta: { auth: true, name: 'Chat' },
    component: ChatIndex
  },
  {
    path: 'messages',
    name: prop + '.email',
    meta: { auth: true, name: 'Messages' },
    component: Notifications
  },
  {
    path: 'email-compose',
    name: prop + '.email.compose',
    meta: { auth: true, name: 'New Email' },
    component: EmailCompose
  },
  {
    path: 'e-commerce/listing',
    name: prop + '.e-commerce.index',
    meta: { auth: true, name: 'Product list' },
    component: ECommerceListing
  },
  {
    path: 'e-commerce/detail',
    name: prop + '.e-commerce.detail',
    meta: { auth: true, name: 'Product Detail' },
    component: EcommerceDetail
  },
  {
    path: 'e-commerce/checkout',
    name: prop + '.e-commerce.cart',
    meta: { auth: true, name: 'Checkout' },
    component: Checkout
  },
  {
    path: 'e-commerce/wish-list',
    name: prop + '.e-commerce.wishlist',
    meta: { auth: true, name: 'Wishlist' },
    component: Wishlist
  },
  {
    path: 'services',
    name: prop + '.project.management',
    meta: { auth: true, name: 'Services', rights: 'access_services' },
    component: Services
  },
  {
    path: 'reminder',
    name: prop + '.reminder',
    meta: { auth: true, name: 'Reminder' },
    component: Reminder
  },
  {
    path: 'treeview',
    name: prop + '.treeview',
    meta: { auth: true, name: 'Tree View' },
    component: AppTreeView
  },
  {
    path: 'company',
    name: prop + '.company',
    meta: { auth: true, name: 'Company', rights: 'edit_company' },
    component: Company
  },
  // {
  //   path: 'accounts',
  //   name: prop + '.accounts',
  //   meta: { auth: true, name: 'accounts' },
  //   component: Accounts
  // },
  // {
  //   path: 'account/:id',
  //   name: prop + '.accounts.edit',
  //   meta: { auth: true, name: 'Edit account' },
  //   component: AccountsEdit
  // },
  // {
  //   path: 'accounts/add',
  //   name: prop + '.accounts.add',
  //   meta: { auth: true, name: 'Add account' },
  //   component: AccountsAdd
  // },
  {
    path: 'program',
    name: prop + '.program',
    meta: { auth: true, name: 'program', rights: 'edit_company' },
    component: Program
  },
  {
    path: 'account',
    name: prop + '.account',
    meta: { auth: true, name: 'Account', rights: 'edit_company' },
    component: Account
  },
  {
    path: 'giftcards',
    name: prop + '.giftcards',
    meta: { auth: true, name: 'Giftcards', rights: 'edit_company' },
    component: Giftcards
  },
  {
    path: 'costs',
    name: prop + '.costs',
    meta: { auth: true, name: 'Costs', rights: 'edit_company' },
    component: Costs
  },
  {
    path: 'notification',
    name: prop + '.notification',
    meta: { auth: true, name: 'Notification', rights: 'edit_company' },
    component: Notification
  },
  {
    path: 'payment',
    name: prop + '.payment',
    meta: { auth: true, name: 'Payment', rights: 'edit_payment' },
    component: Payment
  },
  {
    path: 'builder',
    name: prop + '.builder',
    meta: { auth: true, name: 'Builder', rights: 'edit_website' },
    component: Builder
  },
  {
    path: 'reports/employees',
    name: prop + '.employeesreports',
    meta: { auth: true, name: 'Employees Reports', rights: 'access_reports' },
    component: EmployeesReports
  },
  {
    path: 'reports/services',
    name: prop + '.servicesreports',
    meta: { auth: true, name: 'Services Reports', rights: 'access_reports' },
    component: ServicesReports
  },
  {
    path: 'reports/customers',
    name: prop + '.customersreports',
    meta: { auth: true, name: 'Customers Reports', rights: 'access_reports' },
    component: CustomersReports
  },
  {
    path: 'reports/graphics',
    name: prop + '.graphicsreports',
    meta: { auth: true, name: 'Graphics Reports', rights: 'access_reports' },
    component: GraphicsReports
  },
  {
    path: 'reports/costs',
    name: prop + '.costsreports',
    meta: { auth: true, name: 'Costs Reports', rights: 'access_reports' },
    component: CostsReports
  }
]
const pagesChildCompanyRoutes = (prop) => [
  {
    path: ':id',
    name: prop + '.companies',
    meta: { auth: false },
    component: companyPage
  }
]
const authCustomerChildRoutes = (prop) => [
  {
    path: 'login',
    name: prop + '.login',
    meta: { auth: true },
    component: SignIn2
  },
  {
    path: 'signup',
    name: prop + '.signup',
    meta: { auth: true },
    component: SignUp2
  },
  {
    path: 'password-reset',
    name: prop + '.password-reset',
    meta: { auth: false },
    component: RecoverPassword2
  }
]
const authChildRoutes = (prop) => [
  {
    path: 'login',
    name: prop + '.login',
    meta: { auth: true },
    component: SignIn1
  },
  {
    path: 'signup',
    name: prop + '.signup',
    meta: { auth: true },
    component: SignUp1
  },
  {
    path: 'signup/plan/:id',
    name: prop + '.signuppaid',
    meta: { auth: true },
    component: SignUp1
  },
  {
    path: 'password-reset',
    name: prop + '.password-reset',
    meta: { auth: false },
    component: RecoverPassword1
  },
  {
    path: 'lock-screen1',
    name: prop + '.lock-screen1',
    meta: { auth: true },
    component: LockScreen1
  },
  {
    path: 'confirm-mail1',
    name: prop + '.confirm-mail1',
    meta: { auth: true },
    component: ConfirmMail1
  }
]
const mapChildRoute = (prop) => [
  {
    path: 'google-maps',
    name: prop + '.google',
    meta: { auth: true, name: 'Google map' },
    component: GoogleMaps
  }
]
const defaultlayout = (prop) => [
  {
    path: 'timeline',
    name: prop + '.timeline',
    meta: { auth: true, name: 'Timeline' },
    component: TimeLine
  },
  {
    path: 'invoice',
    name: prop + '.invoice',
    meta: { auth: true, name: 'Invoice' },
    component: Invoice
  },
  {
    path: 'blank-page',
    name: prop + '.blank-page',
    meta: { auth: true, name: 'Blank Page' },
    component: BlankPage
  },
  {
    path: 'pricing',
    name: prop + '.pricing',
    meta: { auth: true, name: 'Pricing' },
    component: Pricing
  },
  {
    path: 'pricing-1',
    name: prop + '.pricing1',
    meta: { auth: true, name: 'Pricing 1' },
    component: Pricing1
  },
  {
    path: 'faq',
    name: prop + '.faq',
    meta: { auth: true, name: 'Faq' },
    component: FAQ
  }
]
const pagesChildRoutes = (prop) => [
  {
    path: 'error/:code',
    name: prop + '.error',
    meta: { auth: true },
    component: ErrorPage
  },
  {
    path: 'coming-soon',
    name: prop + '.coming-soon',
    meta: { auth: true },
    component: ComingSoon
  },
  {
    path: 'maintenance',
    name: prop + '.maintenance',
    meta: { auth: true },
    component: Maintenance
  }
]
const userChildRoute = (prop) => [
  {
    path: 'profile',
    name: prop + '.profile',
    meta: { auth: true, name: 'Profile', rights: 'access_clients' },
    component: Profile
  },
  {
    path: 'user-edit/:id',
    name: prop + '.edit',
    meta: { auth: true, name: 'Edit Profile', rights: 'access_clients' },
    component: ProfileEdit
  },
  {
    path: 'add-user',
    name: prop + '.add',
    meta: { auth: true, name: 'Add Profile', rights: 'access_clients' },
    component: AddUser
  },
  {
    path: 'user-list',
    name: prop + '.list',
    meta: { auth: true, name: 'User List', rights: 'access_clients' },
    component: UserList
  }
]
const employeeChildRoute = (prop) => [
  {
    path: 'profile-edit/:id',
    name: prop + '.edit',
    meta: { auth: true, name: 'Edit Profile', rights: 'access_employees' },
    component: ProfileEditEmployee
  },
  {
    path: 'add-employee',
    name: prop + '.add',
    meta: { auth: true, name: 'Add Profile', rights: 'access_employees' },
    component: AddEmployee
  },
  {
    path: 'employee-list',
    name: prop + '.list',
    meta: { auth: true, name: 'Employees', rights: 'access_employees' },
    component: EmployeeList
  }
]
const socialChildRoute = (prop) => [
  {
    path: 'social',
    name: prop + '.list',
    meta: { auth: true, name: 'Social App' },
    component: SocialApp
  }
]
const customerChildRoute = (prop) => [
  {
    path: 'product/:product',
    name: prop + '.product',
    meta: { auth: false, name: 'Product' },
    component: CustomerProduct
  },
  {
    path: 'cart',
    name: prop + '.cart',
    meta: { auth: false, name: 'Cart' },
    component: CustomerCart
  },
  {
    path: 'services',
    name: prop + '.services',
    meta: { auth: false, name: 'Services' },
    component: CustomerServices
  },
  {
    path: 'company/:company',
    name: prop + '.company',
    meta: { auth: false, name: 'Company' },
    component: CustomerCompany
  },
  {
    path: 'addbooking',
    name: prop + '.add_booking',
    meta: { auth: false, name: 'Add booking' },
    component: CustomerAddBooking
  },
  {
    path: 'bookings',
    name: prop + '.bookings',
    meta: { auth: false, name: 'Bookings' },
    component: CustomerBookings
  },
  {
    path: 'messages',
    name: prop + '.messages',
    meta: { auth: false, name: 'Messages' },
    component: CustomerMessages
  },
  {
    path: 'profile',
    name: prop + '.profile',
    meta: { auth: false, name: 'Profile' },
    component: CustomerProfile
  },
  {
    path: 'signout',
    name: prop + '.signout',
    meta: { auth: false, name: 'Signout' },
    component: CustomerSignout
  }
]
const pluginsChildRoute = (prop) => [
  {
    path: 'datepicker',
    name: prop + '.datepicker',
    meta: { auth: true, name: 'Datepicker' },
    component: DatepickerDemo
  },
  {
    path: 'select',
    name: prop + '.select',
    meta: { auth: true, name: 'Select' },
    component: SelectDemo
  },
  {
    path: 'draggable',
    name: prop + '.draggable',
    meta: { auth: true, name: 'Draggable' },
    component: DragDropDemo
  }
]
const shopChildRoute = (prop) => [
  {
    path: 'products',
    name: prop + '.products',
    meta: { auth: true, name: 'Products' },
    component: ShopProducts
  },
  {
    path: 'orders',
    name: prop + '.orders',
    meta: { auth: true, name: 'Orders' },
    component: ShopOrders
  },
  {
    path: 'shipping',
    name: prop + '.shipping',
    meta: { auth: true, name: 'Shipping' },
    component: ShopShipping
  },
  {
    path: 'settings',
    name: prop + '.settings',
    meta: { auth: true, name: 'Settings' },
    component: ShopSettings
  }
]
const routes = [
  {
    path: '/shop',
    name: 'shop',
    component: VerticleLayout,
    meta: { auth: true },
    children: shopChildRoute('shop')
  },
  {
    path: '/',
    name: 'Dashboard',
    component: VerticleLayout,
    meta: { auth: true },
    children: childRoutes('dashboard')
  },
  {
    path: '/menu-design',
    name: 'horizontal-dashboard',
    component: HorizantalLayout,
    meta: { auth: true },
    children: horizontalRoute('dashboard')
  },
  {
    path: '/core',
    name: 'core',
    component: VerticleLayout,
    meta: { auth: true },
    children: coreChildRoute('core')
  },
  {
    path: '/table',
    name: 'table',
    component: VerticleLayout,
    meta: { auth: true },
    children: tableChildRoute('table')
  },
  {
    path: '/icon',
    name: 'icon',
    component: VerticleLayout,
    meta: { auth: true },
    children: iconChildRoute('icon')
  },
  {
    path: '/auth',
    name: 'auth1',
    component: AuthLayout,
    meta: { auth: true },
    children: authChildRoutes('auth1')
  },
  {
    path: '/customer-auth',
    name: 'customer-auth',
    component: CustomerAuthLayout,
    meta: { auth: true },
    children: authCustomerChildRoutes('customer-auth')
  },
  {
    path: '/default',
    name: 'default',
    meta: { auth: false },
    component: defaultPage
  },
  {
    path: '/companies',
    name: 'companies',
    meta: { auth: false },
    component: Default,
    children: pagesChildCompanyRoutes('companies')
  },
  {
    path: '/pages',
    name: 'pages',
    component: Default,
    meta: { auth: true },
    children: pagesChildRoutes('default')
  },
  {
    path: '/extra-pages',
    name: 'extra-pages',
    component: VerticleLayout,
    meta: { auth: true },
    children: defaultlayout('extra-pages')
  },
  {
    path: '/app',
    name: 'app',
    component: VerticleLayout,
    meta: { auth: true },
    children: appChildRoute('app')
  },
  {
    path: '/user',
    name: 'user',
    component: VerticleLayout,
    meta: { auth: true },
    children: userChildRoute('user')
  },
  {
    path: '/employee',
    name: 'employee',
    component: VerticleLayout,
    meta: { auth: true },
    children: employeeChildRoute('employee')
  },
  {
    path: '/app/buyplan/:id/',
    name: 'buyplan',
    meta: { auth: true },
    component: BuyPlan
  },
  {
    path: '/app/payBooking/:token',
    name: 'paybooking',
    meta: { auth: false },
    component: CustomerPaymentIos
  },
  {
    path: '/social',
    name: 'social',
    component: VerticleLayout,
    meta: { auth: true },
    children: socialChildRoute('social')
  },
  {
    path: '/map',
    name: 'map',
    component: VerticleLayout,
    meta: { auth: true },
    children: mapChildRoute('map')
  },
  {
    path: '/form',
    name: 'form',
    component: VerticleLayout,
    meta: { auth: true },
    children: formChildRoute('form')
  },
  {
    path: '/plugins',
    name: 'plugins',
    component: VerticleLayout,
    meta: { auth: true },
    children: pluginsChildRoute('plugins')
  },
  {
    path: '/customer',
    name: 'customer',
    component: VerticleLayout,
    meta: { auth: true },
    children: customerChildRoute('customer')
  },
  {
    path: '/callback',
    name: 'callback',
    meta: { auth: false },
    component: Callback
  },
  {
    path: '/:id/activateAccount',
    name: 'Activate account',
    meta: { auth: false },
    component: ActivateAccount
  },
  {
    path: '/:id/resetPassword',
    name: 'Reset password',
    meta: { auth: false },
    component: ResetPassword
  },
  {
    path: '/:id/resetCustomerPassword',
    name: 'Reset customer password',
    meta: { auth: false },
    component: ResetCustomerPassword
  },
  {
    path: '/:id/booking',
    name: 'book',
    meta: { auth: false },
    component: CalendarBook
  },
  {
    path: '/:id/booking/:employee',
    name: 'book',
    meta: { auth: false },
    component: CalendarBook
  },
  {
    path: '/:id',
    name: 'website',
    meta: { auth: false },
    component: Website
  },
  {
    path: '/auth/facebook',
    name: 'authFacebook',
    meta: { auth: false },
    component: FacebookAuth
  }
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (from && to.name === from.name) {
      return
    }
    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
  console.log(this)
  if (document.body.className === 'sidebar-main') {
    document.body.className = ''
  }
  const publicPages = ['/companies/' + to.params.id, '/app/payBooking/' + to.params.token, '/app/buyplan/' + to.params.id + '/' + to.params.token, '/' + to.params.id + '/activateAccount', '/auth/facebook', '/' + to.params.id + '/booking', '/' + to.params.id + '/booking/' + to.params.employee, '/' + to.params.id + '/resetPassword', '/' + to.params.id + '/resetCustomerPassword', '/auth/login', '/auth/signup', '/auth/password-reset', '/auth/signup/plan/1', '/auth/signup/plan/2', '/auth/signup/plan/3', '/auth/signup/plan/4', '/default', '/customer-auth/login', '/customer-auth/signup', '/customer-auth/password-reset']
  if (publicPages.includes(to.path)) {
    localStorage.removeItem('customer_api_token')
    localStorage.removeItem('customer_token')
    localStorage.removeItem('user')
    localStorage.removeItem('api_token')
    localStorage.removeItem('company_token')
    return next()
  }
  // check if customer or company
  const loggedInCustomer = localStorage.getItem('customer_api_token')
  if (loggedInCustomer) {
    if (to.meta.auth) {
      return next('/customer/services')
    }
    if (!to.matched.length) {
      return next('/customer/services')
    }
    return next()
  }

  const loggedIn = localStorage.getItem('api_token')
  if (loggedIn) {
    let userRights = null
    if (localStorage.getItem('userRights')) {
      userRights = JSON.parse(JSON.parse(localStorage.getItem('userRights')))
    }
    if (userRights !== null && userRights[to.meta.rights] === false) {
      core.showSnackbar('error', 'You don\'t have enough rights!')
    } else {
      if (!to.matched.length) {
        return next('/app/calendar')
      }
      const authRequired = !publicPages.includes(to.path)
      if (to.meta.auth) {
        if (authRequired && !loggedIn) {
          return next('/auth/login')
        } else if (to.name === 'dashboard' || to.name === 'mini.dashboard') {
          return next('/app/calendar')
        }
      }
      next()
    }
  } else {
    return next('/default')
  }
})

export default router
