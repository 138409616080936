import '@babel/polyfill'
import Vue from 'vue'
import 'mutationobserver-shim'
import './Utils/fliter'
import App from './App.vue'
import router from './router'
import store from './store'
import Raphael from 'raphael/raphael'
import './plugins'
import './registerServiceWorker'
import AlgoliaComponents from 'vue-instantsearch'
import i18n from './i18n'
import './directives'
import vuetify from '@/plugins/vuetify'
import * as VueGoogleMaps from 'vue2-google-maps'

global.Raphael = Raphael

Vue.use(AlgoliaComponents)

Vue.config.productionTip = false

Vue.use(
  VueGoogleMaps, {
    load: {
      key: 'AIzaSyC9nzHIDzlEgU8wdxDW25l5BtvHmbZvb2c',
      libraries: 'places'
    }
  }
)

setTimeout(() => {
  const vm = new Vue({
    router,
    store,
    i18n,
    vuetify,
    render: h => h(App)
  }).$mount('#app')
  window.vm = vm
}, 100)
