import Axios from 'axios'
import { API } from '../../config/api.config'
const state = {
  status: '',
  error: '',
  token: localStorage.getItem('customer_api_token') || '',
  companyToken: localStorage.getItem('customer_token') || '',
  company: {
    hash: '',
    id: '',
    name: '',
    token: '',
    email: '',
    country: '',
    language: '',
    phone: '',
    active: '',
    plan: ''
  }
}

const getters = {
  isLoggedIn: state => !!state.token,
  authStatus: state => state.status,
  theError: state => state.error
}

const actions = {
  customerLogin ({ commit }, company) {
    return new Promise((resolve, reject) => {
      commit('auth_request')
      Axios.post(API.API_LOGIN_CUSTOMER_URL, company)
        .then(resp => {
          const token = resp.data.api_key
          const user = JSON.parse(resp.data.user)
          localStorage.setItem('customer_api_token', token)
          localStorage.setItem('customer_token', user.token)
          Axios.defaults.headers.common.Authorization = token
          commit('auth_success', token, user)
          resolve(resp)
        })
        .catch(err => {
          commit('auth_error')
          localStorage.removeItem('customer_api_token')
          localStorage.removeItem('customer_token')
          reject(err)
        })
    })
  },
  logout ({ commit }) {
    return new Promise((resolve, reject) => {
      commit('logout')
      localStorage.removeItem('customer_api_token')
      localStorage.removeItem('customer_token')
      delete Axios.defaults.headers.common.Authorization
      resolve()
    })
  },
  signup ({ commit }, company) {
    return new Promise((resolve, reject) => {
      commit('auth_request')
      Axios.post(API.API_SIGNUP_CUSTOMER_URL, company)
        .then(resp => {
          localStorage.removeItem('customer_api_token')
          localStorage.removeItem('customer_token')
          if (resp.data.status === 'success') {
            const token = resp.data.api_key
            const user = JSON.parse(resp.data.user)
            localStorage.setItem('customer_api_token', token)
            localStorage.setItem('customer_token', user.token)
            commit('auth_success', user.api_key, user)
            resolve(resp)
          } else if (resp.data.status === 'fail') {
            commit('auth_error', resp.data.message)
            localStorage.removeItem('customer_api_token')
            localStorage.removeItem('customer_token')
            resolve(resp)
          }
        })
        .catch(({ response }) => {
          console.log(response.data.message)
          commit('auth_error')
          localStorage.removeItem('customer_api_token')
          localStorage.removeItem('customer_token')
        })
    })
  }
}

const mutations = {
  auth_request (state) {
    state.status = 'loading'
  },
  auth_success (state, token, user) {
    state.status = 'success'
    state.token = token
    state.user = user
  },
  auth_error (state, error) {
    state.error = error
  },
  logout (state) {
    state.status = ''
    state.token = ''
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
